<template>
  <div class="pt-5 person-finder">
    <v-container class="mt-0 pb-0 px-6 finder-body">
      <v-row
        class="px-2 py-4" 
        align="end"
      >
        <v-col 
          class="py-0"
          cols="12"
          xl="10"
          md="9"
          sm="7"
          xs="12"
        >
          <h1>
            Person Finder
          </h1>
        </v-col>
        <v-col class="ml-auto py-0">
          <v-text-field
            v-if="isAdmin || isSupport"
            placeholder="BYU ID or NetID"
            solo
            single-line
            dense
            outlined
            flat
            hide-details
            :loading="isLoading"
            :error-messages="lookupErrors"
            @input="$v.lookupID.$touch()"
            @blur="$v.lookupID.$touch()"
            append-icon="mdi-chevron-right"
            v-model="lookupID"
            @keyup.enter="getPerson"
            class="pt-4"
          />
        </v-col>
      </v-row>
      <v-divider />

      <v-row v-if="person">
        <v-col 
          cols="12"
          xl="3"
          lg="4"
          md="5"
          sm="12"
          xs="12"
          class="py-0"
        >
          <v-row class="py-2 px-4">
            <v-col class="py-0">
              <p class="name">
                {{ person.first_name }} {{ person.last_name }}
              </p>
              <p class="details">
                {{ person.byu_id }} - {{ person.net_id }}
              </p>
              <p 
                v-if="person.restricted"
                class="restricted"
              >
                Restricted
              </p>
            </v-col>
            <v-col>
              <v-row 
                class="fill-height" 
                align="start" 
                justify="end"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      elevation="0"
                      small
                      @click.stop="logDialog = true"
                      class="mx-2"
                    >
                      <v-icon v-on="on">
                        mdi-list-status
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>View Logs</span>
                </v-tooltip>
                
                <v-dialog
                  v-model="logDialog"
                  max-width="1000px"
                >
                  <Logs
                    name="logs"
                    :logging-data="personLogs"
                  />
                </v-dialog>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      elevation="0"
                      small
                      @click="syncPerson()"
                      class="mx-2"
                      :disabled="person.sync"
                      v-on="on"
                    >
                      <v-icon>
                        mdi-sync
                      </v-icon>
                    </v-btn>
                  </template>
                  <span v-if="!person.sync">Sync User</span>
                  <span v-else>Sync Pending</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-row>
          <v-divider />
          <v-card
            v-if="person"
            elevation="0"
          >
            <v-text-field
              placeholder="Filter groups"
              solo
              single-line
              dense
              flat
              hide-details
              append-icon="mdi-magnify"
              v-model="search"
              class="my-2"
            />
            <v-divider />
            <v-list
              class="overflow-y-auto py-0 mx-2 attribute-list"
            >
              <v-list-item-group 
                v-model="selectedAttribute"
                color="accent"
              >
                <v-list-item
                  v-for="attribute in filteredAttributes"
                  :key="attribute.value"
                  :disabled="!usedAttributes.includes(attribute)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <h5>{{ attribute }}</h5>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-divider />
        </v-col>
        <!-- <v-divider 
          vertical 
          inset 
          class="px-2"
        /> -->
        <v-col class="py-4">
          <!-- Applications -->
          <PersonApplications
            style="height: calc(100vh - 420px); overflow-x: hidden"
            v-if="person"
            :elements="person.elements"
            :selected-attribute="filteredAttributes[selectedAttribute]"
            :isAdmin="this.isAdmin"
            :isSupport="this.isSupport"
            @reloadApplications="getPerson()"
          />
        </v-col>
      </v-row>

      <v-row 
        v-else
        class="mx-auto py-5"
        align="center"
        justify="center"
      >
        <v-progress-circular v-if="!personNotFound" indeterminate />
        <h3 v-else>
          Person Not Found
        </h3>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { validationMixin } from 'vuelidate'
import { maxLength } from 'vuelidate/lib/validators'

// components
import PersonApplications from "@/router/components/PersonApplications";
import Logs from "@/router/components/Logs";

// TODO: Queries - move to other file
const getPersonByNetID = `query ($lookup_id: String!, $nextToken: String) {
  personByNetID(net_id: $lookup_id) {
    items {
      first_name
      last_name
      byu_id
      net_id
      restricted
      gender
      sync
      attributes
      elements(nextToken: $nextToken) {
        items {
          id
          type
          createdAt
          TTL
          scheduledDate
          element {
            alias
            application
            attributes
            removal_time
          }
        }
        nextToken
      }
    }
  }
}`

const getPersonByBYUID = `query ( $lookup_id: String!, $nextToken: String ) {
  getPerson(byu_id: $lookup_id) {
    first_name
    last_name
    byu_id
    net_id
  	restricted
  	attributes
  	elements(nextToken: $nextToken) {
    	items {
        id
        type
        createdAt
        TTL
        scheduledDate
        element {
          alias
          application
          attributes
          removal_time
        }
      }
      nextToken
    }
  }
}`

const syncPersonMutation = `mutation ( $byu_id: String! ) {
  updatePerson(input: {
    byu_id: $byu_id
    sync: true
    genetec_update_person: true
  }) {
    byu_id  
  }
}`

const getPersonLogs = `query ($byu_id: String!) {
  getPerson(byu_id: $byu_id) {
    logs {
      items {
        action
        createdAt
        type
        element {
          application
          alias
        }
        byu_id
      }
      nextToken
    }
  }
}`

const getPersonGroups = `query ($lookup_id: String!) {
  personByNetID(net_id: $lookup_id) {
    items {
      attributes
    }
  }
}`

export default {
  name: "Profile",
  components: {
    PersonApplications,
    Logs
  },
  mixins: [ validationMixin ],
  validations: {
    lookupID: { maxLength: maxLength(9) }
  },
  data () {
    return {
      lookupID: this.$store.state.user ? this.$store.state.user.attributes.name.split('@')[0] : '',
      logDialog: false,
      personLogs: [],
      isLoading: true,
      selectedAttribute: undefined,
      search: '',
      person: undefined,
      personNotFound: false,
      applications: {},
      isAdmin: false,
      isSupport: false
    }
  },
  created () {
    this.checkAdmin()
    this.getPerson()
  },  
  computed: {
    lookupErrors () {
      const errors = []
      if (!this.$v.lookupID.$dirty) return errors
      !this.$v.lookupID.maxLength && errors.push('Lookup ids are less than 10 characters')

      this.personNotFound && errors.push('Person not found')
      
      return errors
    },

    filteredAttributes () {
      var self = this;
      
      let usedGroups = [...new Set(this.person.elements.map(item => item.element.attributes))].flat()

      if (this.person.attributes) {
          return self.person.attributes.filter(
            function (attribute) {
              return attribute.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
            }
          )
          .sort(
            function (a, b) {
              return a.localeCompare(b, 'en', {'sensitivity': 'base'});
            }
          )
          .sort(
            function(a, b) {
              let aUsed = usedGroups.includes(a)
              let bUsed = usedGroups.includes(b)

              if (aUsed && !bUsed) {
                return -1
              }

              if (!aUsed && bUsed) {
                return 1
              }

              return 0
            }
          )
      } else {
        return []
      }
    },

    usedAttributes () {
      return [...new Set(this.person.elements.map(element => element.element.attributes))].flat()
    }
  },

  methods: {
    async checkAdmin() {
      await API.graphql({
        query: getPersonGroups, 
        variables: {lookup_id: this.$store.state.user.attributes.name.split('@')[0]}
      })
        .then(data => {
          let attributes = data.data.personByNetID.items[0].attributes
          this.isAdmin = attributes.includes('provisioning_admins')
          this.isSupport = attributes.includes('provisioning_semi_admin')
        })
        .catch(error => {
          console.log(error)
        })
    },
    
    async getPerson () {
      // Validation
      this.$v.$touch()

      // Lookup
      this.isLoading = true
      var getPersonQuery = ''

      // Check if BYU ID or NetID
      if (this.lookupID.length === 9) {
        getPersonQuery = getPersonByBYUID
      } else {
        getPersonQuery = getPersonByNetID
      }

      var loadedData = {}
      let loadedElements = []
      let nextToken = "nullToken"
      while (nextToken !== null) {
        if (nextToken === 'nullToken') {
          nextToken = null;
        }
        // Query
        await API.graphql({
          query: getPersonQuery,
          variables: {lookup_id: this.lookupID, nextToken: nextToken},
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
        .then(response => {
          if ('getPerson' in response.data) {
            loadedData = response.data.getPerson
            nextToken = response.data.getPerson.elements.nextToken
            loadedElements = loadedElements.concat(loadedData.elements.items)
            this.personNotFound = false
            console.log('yeeeeeee')
          } else {
            if (response.data.personByNetID.items.length == 1) {
              loadedData = response.data.personByNetID.items[0]
              nextToken = response.data.personByNetID.items[0].elements.nextToken
              loadedElements = loadedElements.concat(loadedData.elements.items)
              this.personNotFound = false
            } else {
              this.personNotFound = true
            }
          }
        })
        .catch(error => {
          console.log(error)
          if ('data' in error) {
            if ('getPerson' in error.data) {
              loadedData = error.data.getPerson
              this.personNotFound = false
            } else {
              if (error.data.personByNetID.items.length) {
                loadedData = error.data.personByNetID.items[0]
                this.personNotFound = false
              } else {
                console.log(error)
                this.personNotFound = true
              }
            }
          }
        });
      }

      nextToken = "nullToken";
      if (!this.personNotFound) {
        console.log("person found")
        
        this.person = loadedData
        this.person.elements = loadedElements.filter(function(val) {return val !== null})

        this.getLogs(this.person.byu_id)
      } else {
        console.log('person not found')
        this.person = undefined
      }
      this.isLoading = false
    },

    // Search Element Logs
    async getLogs(byu_id) {
      console.log("Getting Logs")

      await API.graphql(graphqlOperation(
        getPersonLogs, { byu_id: byu_id }
      )).then(response => {
        this.personLogs = response.data.getPerson.logs.items.filter(function(val) { return val !== null; })

      }).catch(error => {
        this.personLogs = error.data.getPerson.logs.items.filter(function(val) { return val !== null; })
        console.log(error)
      })

      if (!this.personLogs.length) {
        this.personLogs = [];
      } else {
        console.log(this.personLogs)
        
        this.personLogs.sort(function(a,b){
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      }
    },

    syncPerson () {
      console.log("Syncing person")
      API.graphql(graphqlOperation(
          syncPersonMutation, { byu_id: this.person.byu_id }
      ))
      .then(this.person.sync = true)
      .catch(error => console.log(error))
    }
  }
}

</script>


<style scoped>
.person-finder {
  background-color: #F5F5F5;
  height: 100%;
}

.finder-body {
  background-color: #ffffff;
  height: 97%;
  overflow-y: hidden;
}

.attribute-list {
  height: 20vh;
  background-color: #FCFCFC
}

h1 {
  font-size: 24px;
}

h5 {
  font-size: 1.2em;
  font-weight: lighter;
}

p {
  padding: 0;
  margin: 0;
}
.name {
  font-size: 22px;
  font-weight: lighter;
}

.details {
  font-size: 16px;
  font-weight: 300
}

.restricted {
  font-weight: bold;
  color: #ED2323;
}

@media only screen and (min-width: 956px) {
  .attribute-list {
    height: calc(100vh - 550px); 
  }
}

</style>
<template>
  <v-card 
    light
    class="pa-4"
  >
    <v-card-title class="pa-2">
      Logs
    </v-card-title>
    <v-card-text>
      <v-card elevation="0">
        <v-data-table
          :headers="logHeaders"
          :items="loggingData"
          :items-per-page="10"
        >
          <template v-slot:item.createdAt="{ item }">
            {{ new Date(item.createdAt).toLocaleString() }}
          </template>                          
        </v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Logs',
  components: {},
	props: [ "loggingData" ],
	data () {
		return {
      logHeaders: [
        { text: 'Status', align: 'start', value: 'type'},
        { text: 'Action', align: 'start', value: 'action'},
        { text: 'Application', align: 'start', value: 'element.application'},
        { text: 'Element', align: 'start', value: 'element.alias'},
        { text: 'Date', align: 'start', value: 'createdAt'}
      ]
		}
  }
}
</script>
